export const autoComplete = [
  "ENABLE_NB_IOT",
  "DISABLE_NB_IOT",
  "CONNECT_NOW",
  "SET_CELL_OPERATOR 0",
  "GET_CELL_OPERATORS",
  "GET_COMUNICATION_CONFIG",
  "GET_SERIAL_NUMBER",
  "MCU_GET_VER",
  "--------------",
  "RESET_SIM_CARD",
  "RESET_UNIT",
  "BLE_VER",
  "BLE_ENTER_STOP_MODE",
  "BLE_EXIT_STOP_MODE",
  "GET_UNIT_TIME",
  "SET_UNIT_TIME 1607266608",
  "MCU_ENTER_STOP_MODE",
  "MODEM_STATUS",
  "MCU_GET_DI_STATE 1",
  "MCU_SERIAL_FLASH",
  "GET_SOURCE_VOLTAGE",
  "ENABLE_HIGH_VDC",
  "DISABLE_HIGH_VDC",
  "ENABLE_MODEM_CURRENT",
  "DISABLE_MODEM_CURRENT",
  "ENABLE_AIN 1",
  "DISABLE_AIN 1",
  "GET_AIN 1",
  "SWITCH_AIN_CURRENT_MODE 1",
  "SWITCH_AIN_VOLTAGE_MODE 1",
  "ENABLE_CAPACITOR_CHARGING",
  "DISABLE_CAPACITOR_CHARGING",
  "OPEN_VALVE 1",
  "CLOSE_VALVE 1",
  "TURN_ON_MODEM",
  "TURN_OFF_MODEM",
  "START_WORKING_WITH_MODEM",
  "STOP_WORKING_WITH_MODEM",
  "START_CONNECTIVITY",
  "STOP_CONNECTIVITY",
  "ENABLE_PRINTF",
  "DISABLE_PRINTF",
  "BLE_ENABLE",
  "UART_ENABLE",
  "EXIT_TRANSPARENT_MODE",
  "SIM_REGISTRATION_CHECK",
  "MODEM_RSSI",
  "SET_DEAA_CONFIG 400943,Ii1uU6cC9Oo4Rr6,01,10,10",
  "MODEM_CONNECT_SERVER",
  "MODEM_DISCONNECT_SERVER",
  'SET_COMUNICATION_CONFIG gigsky-02,"","",prodback.bermad.io,7071',
  "PAUSE_RESUME_PRG 0 1",
  "START_STOP_PRG 0 1",
  "++++++++++++",
  "GET_COMMUNICATION_CONFIG",
  "GET_CONNECTION_STATUS",
  'SET_COMMUNICATION_CONFIG gigsky-02,"","",prodback.bermad.io,7071,042503',
  "OPEN_VLV1",
  "OPEN_VLV2",
  "CLOSE_VLV1",
  "CLOSE_VLV2",
  "RESET_LOG",
  "FACTORY_RESET",
  "ENABLE_STORAGE_MODE",
  "DISABLE_STORAGE_MODE",
  "SET_SCAN_MODE",
  "SET_IOT_MODE",
  "GET_SIM_PROFILE",
  "SET_SIM_PROFILE",
];
